import VueRouter from 'vue-router';
import Vue from 'vue';
import Home from '@/views/Home.vue';
Vue.use(VueRouter);

const Routes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import('../views/Scan.vue'),
  },
  {
    path: '/dinein/:tableNo',
    name: 'dinein',
    component: () => import('../views/DineIn.vue'),
  },
  {
    path: '/menubook',
    name: 'menubook',
    component: () => import('../views/MenuBook.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/Menu.vue'),
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../views/Product.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { redirectIfLoggedIn: true },
    props: true,
    component: () => import('../views/Auth/Login.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { redirectIfLoggedIn: true },
    component: () => import('../views/Auth/Signup.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    meta: { redirectIfLoggedIn: true },
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('../views/Promotion.vue'),
  },
  {
    path: '/loyalty/signup',
    name: 'loyalty-signup',
    meta: { requiresAuth: true, redirectIfSignedup: true },
    component: () => import('../views/Loyalty/Signup.vue'),
  },
  {
    path: '/loyalty/reward',
    name: 'loyalty-reward-tabs',
    meta: { requiresAuth: true },
    component: () => import('../views/Loyalty/Reward/Tabs.vue'),
  },
  {
    path: '/loyalty/tier',
    name: 'loyalty-reward-tier',
    meta: { requiresAuth: true },
    component: () => import('../views/Loyalty/Reward/Tier.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { redirectIfLoggedIn: true },
    props: true,
    component: () => import('../views/Auth/Register.vue'),
  },
  {
    path: '/google/redirect',
    name: 'googleredirect',
    meta: { redirectIfLoggedIn: true },
    props: true,
    component: () => import('../views/Auth/Google/Redirect.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: { requiresAuth: true },
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/payment/:orderId',
    name: 'payment',
    meta: { requiresAuth: true },
    component: () => import('../views/Payment.vue'),
  },
  {
    path: '/whatsnew',
    name: 'whatsnew',
    component: () =>
      import(/* webpackChunkName: "whatsnew" */ '../views/WhatsNew'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(
        /* webpackChunkName: "orders" */ '../views/OrderHistory/OrderHistory'
      ),
  },
  {
    path: '/order/:orderId',
    name: 'order',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "order" */ '../views/OrderHistory/Order'
      ),
  },
  {
    path: '/reservations',
    name: 'reservation',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "reservation" */ '../views/Reservation/Reservation'
      ),
  },
  {
    path: '/reserve',
    name: 'reserve',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "reserve" */ '../views/Reserve/Reserve'),
  },
  {
    path: '/reserve/done',
    name: 'reservedone',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "reservationdone" */ '../views/ReservationConfirm'
      ),
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () =>
      import(
        /* webpackChunkName: "vouchers" */ '../views/Voucher/List'
      ),
  },
  {
    path: '/voucher/:voucherCode/claim',
    name: 'claimvoucher',
    component: () =>
      import(
        /* webpackChunkName: "claimvoucher" */ '../views/Voucher/Claim'
      ),
  },
  {
    path: '/loyalty/points',
    name: 'loyalty-points',
    meta: { requiresAuth: true, requiresMember: true, },
    component: () =>
      import(
        /* webpackChunkName: "loyalty-points" */ '../views/Loyalty/Point/List'
      ),
  },
  {
    path: '/loyalty/rewards',
    name: 'loyalty-rewards',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "loyalty-rewards" */ '../views/Loyalty/Reward/List'
      ),
  },
  {
    path: '/receipt/:token',
    name: 'receipt',
    component: () =>
      import(
        /* webpackChunkName: "thankyoupage" */ '../views/Receipt'
      ),
  },
  {
    path: '/reviews',
    name: 'reviews',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "merchantreview" */ '../views/MerchantReviews'
      ),
  },
  {
    path: '/addresses',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "AddressWrap" */ '../views/Addresses/AddressesWrap'
      ),
    children: [
      {
        path: '',
        name: 'addresseshome',
        component: () =>
          import(
            /* webpackChunkName: "Addresshome" */ '../views/Addresses/Addresses'
          ),
      },
      {
        path: 'add',
        name: 'addaddress',
        component: () =>
          import(
            /* webpackChunkName: "AddressAdd" */ '../views/Addresses/AddressFormScreen'
          ),
        props: {
          title: 'Add Address',
        },
      },
      {
        path: 'edit',
        name: 'editaddress',
        component: () =>
          import(
            /* webpackChunkName: "AddressEdit" */ '../views/Addresses/AddressFormScreen'
          ),
        props: {
          title: 'Edit Address',
        },
      },
    ],
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  routes: Routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});

export default router;
