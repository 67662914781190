import loyaltyApi from '../../api/loyalty';
const state = {
  customer: localStorage.loyaltyCustomer ? JSON.parse(localStorage.loyaltyCustomer) : null,
  tiers: [],
  reward: null,
  rewards: [],
  points: [],
  vouchers: [],
  pointBalance: 0,
};

const mutations = {
  SET_TIERS(state, tiers) {
    state.tiers = tiers;
  },
  SET_REWARD(state, reward) {
    state.reward = reward;
  },
  SET_REWARDS(state, rewards) {
    state.rewards = rewards;
  },
  SET_POINTS(state, points) {
    state.points = points;
  },
  SET_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers;
  },
  SET_CUSTOMER(state, customer) {
    if (customer) {
      localStorage.loyaltyCustomer = JSON.stringify(customer);
      state.customer = customer;
    } else {
      localStorage.removeItem('loyaltyCustomer');
      state.customer = null;
    }
  },
  SET_POINT_BALANCE(state, pointBalance) {
    state.pointBalance = pointBalance;
  },
};

const actions = {
  async create(context, data) {
    const res = await loyaltyApi.create(context.rootState.auth.token, data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }
    return res.data;
  },
  async getLoyaltyTiers(context, query) {
    let tiers = (await loyaltyApi.findAllTiers(query))?.data;

    context.commit('SET_TIERS', tiers);
    return tiers;
  },
  async redeemLoyaltyReward(context, { id, data }) {
    const res = await loyaltyApi.createRedeemReward(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, id, data);
    if (res?.status != 200) {
      throw Error(res.data);
    }
    return res.data;
  },
  async getLoyaltyReward(context, { id, query }) {
    const res = await loyaltyApi.findReward(id, query);
    if (res?.status != 200) {
      throw Error(res.data.message);
    }
    context.commit('SET_REWARD', res?.data);
    return res?.data;
  },
  async getLoyaltyRewards(context, query) {
    let rewards = (await loyaltyApi.findAllRewards(context.rootState.auth.token, query))?.data;

    context.commit('SET_REWARDS', rewards);
    return rewards;
  },
  async getLoyaltyPoints(context, query) {
    let points = (await loyaltyApi.findAllPoints(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, query))?.data;

    context.commit('SET_POINTS', points);
    return points;
  },
  async getLoyaltyVouchers(context, query) {
    let vouchers = (await loyaltyApi.findAllVouchers(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, query))?.data;

    context.commit('SET_VOUCHERS', vouchers);
    return vouchers;
  },
  async getLoyaltyCustomer(context, query) {
    const res = await loyaltyApi.findCustomer(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, query);

    if (res?.status != 200) {
      throw Error(res.data.message);
    }

    context.commit('SET_CUSTOMER', res?.data);
    return res?.data;
  },
  async patchLoyaltyCustomerPWA(context, data) {
    const res = await loyaltyApi.patch(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, 'pwa', data);

    if (res?.status != 200) {
      throw Error(res.data.message);
    }

    return res?.data;
  },
  async getLoyaltyPointBalance(context, query) {
    const res = await loyaltyApi.findPointBalance(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, query);

    if (res?.status != 200) {
      throw Error(res.data.message);
    }

    context.commit('SET_POINT_BALANCE', res?.data);
    return res?.data;
  },
};

const getters = {
  isMember(state) {
    return state.customer ? true : false;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
