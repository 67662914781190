import api from './index';

const url = 'menu';

export default {
  async findItem(itemId, params) {
    return api.get(`${url}/${itemId}`, {
      params,
    });
  },
  async findAllItems(params) {
    return api.get(`${url}/item`, {
      params,
    });
  },
  async findAllCategories(params) {
    return api.get(`${url}/category`, {
      params,
    });
  },
  async findAllSizes(params) {
    return api.get(`${url}/size`, {
      params,
    });
  },
  async findAllAddonCategories(params) {
    return api.get(`${url}/addon-category`, {
      params,
    });
  },
  async findAllAddonItems(params) {
    return api.get(`${url}/addon-item`, {
      params,
    });
  },
};
