import reservationApi from '../../api/reservation';
import dayjs from 'dayjs';

const state = {
  history: [],
  upcoming: [],
};

const getters = {};

const mutations = {
  SET_LIST(state, reservations) {
    if (reservations) {
      reservations.forEach(reservation => {
        const reservedDateTime = `${reservation.reservedDate.split('T')[0]}T${
          reservation.reservedTime
        }`;
        const dateTime = dayjs(new Date(reservedDateTime));
        reservation.dateTime = dateTime.format('YYYY/MM/DD hh:mm a');
        if (dayjs().isBefore(dateTime)) {
          state.upcoming.push(reservation);
        } else {
          state.history.push(reservation);
        }
      });
    } else {
      state.history = [];
      state.upcoming = [];
    }
  },
};

const actions = {
  createReservation(context, data) {
    var token = context.rootState.auth.token;

    return new Promise((resolve, reject) => {
      reservationApi
        .create(token, data)
        .then(res => {
          resolve(res);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  listReservation(context) {
    var token = context.rootState.auth.token;

    return new Promise((resolve, reject) => {
      reservationApi
        .list(token)
        .then(res => {
          context.commit('SET_LIST', res.data);
          resolve(res);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
