import paymentApi from '../../api/payments';

const state = {
  credentials: {},
};

const actions = {
  async getCredentials(context, { type, params }) {
    const credentials = (
      await paymentApi.getCredentials(
        context.rootState.auth.token,
        type,
        params
      )
    ).data;
    context.commit('SET_CREDENTIALS', credentials);
    return credentials;
  },
};

const getters = {};

const mutations = {
  SET_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
