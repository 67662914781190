import api from './index';

const url = 'reservation';

export default {
  async create(token, data) {
    return api.post(`${url}`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  async list(token, query) {
    return api.get(`${url}`, {
      params: query,
      headers: {
        Authorization: token,
      },
    });
  },
  async find(token, id) {
    return api.get(`${url}/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  },
};
