import mediaApi from '../../api/media';

const state = {
  banners: [],
};

const getters = {
};

const mutations = {
  SET_BANNERS(state, banners) {
    state.banners = banners;
  },
};

const actions = {
	async getBanners(context, query) {
    let banners = (await mediaApi.findAllBanners(query))?.data;

    context.commit('SET_BANNERS', banners);
    return banners;
	},
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
