import api from './index';

const url = 'loyalty';

export default {
  async create(token, data) {
    return api.post(`${url}`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  async findAllTiers(query) {
    return api.get(`${url}/tier`, {
      params: query,
    });
  },
  async findAllRewards(token, query) {
    return api.get(`${url}/reward`, {
      headers: {
        Authorization: token,
      },
      params: query,
    });
  },
  async findReward(token, id, query) {
    return api.get(`${url}/reward/${id}`, {
      headers: {
        Authorization: token,
      },
      params: query,
    });
  },
  async createRedeemReward(token, hqMerchantId, merchantId, id, data) {
    return api.post(`${url}/reward/${id}/redeem`, data, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
    });
  },
  async findAllVouchers(token, hqMerchantId, merchantId, query) {
    return api.get(`${url}/voucher`, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
      params: query,
    });
  },
  async findCustomer(token, hqMerchantId, merchantId, query) {
    return api.get(`${url}/customer`, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
      params: query,
    });
  },
  async patch(token, hqMerchantId, merchantId, column, data) {
    return api.patch(`${url}/customer/${column}`, data, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
    });
  },
  async findAllPoints(token, hqMerchantId, merchantId, query) {
    return api.get(`${url}/point`, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
      params: query,
    });
  },
  async findPointBalance(token, hqMerchantId, merchantId, query) {
    return api.get(`${url}/point/balance`, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
      params: query,
    });
  },
};
