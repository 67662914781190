import api from './index';

const url = 'client';
export default {
  async create(data) {
    return api.post(`${url}`, data);
  },
  async update(token, data) {
    return api.put(`${url}`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  async find(token, params) {
    return api.get(`${url}`, {
      headers: {
        Authorization: token,
      },
      params,
    });
  },
  async findAllVouchers(token, params) {
    return api.get(`${url}/voucher`, {
      headers: {
        Authorization: token,
      },
      params,
    });
  },
  async findVoucher(token, id) {
    return api.get(`${url}/voucher/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  },
};
