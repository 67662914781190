import api from './index';

const url = 'customer';
export default {
  async create(data) {
    return api.post(`${url}`, data);
  },
  async login(data) {
    return api.post(`${url}/login`, data);
  },
  async resetPassword(data) {
    return api.post(`${url}/reset-password`, data);
  },
  async findVoucher(token, id) {
    return api.get(`${url}/voucher/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  async findAllVouchers(token, params) {
    return api.get(`${url}/voucher`, {
      headers: {
        Authorization: token,
      },
      params,
    });
  },
  async claimVoucher(token, data) {
    return api.post(`${url}/voucher/claim`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  findAllAddresses(token) {
    return api.get(`${url}/address`, {
      headers: {
        Authorization: token,
      },
    });
  },
  getAddress(token, id) {
    return api.get(`${url}/address/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  createAddress(token, data) {
    return api.post(`${url}/address`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  updateAddress(token, id, data) {
    return api.put(`${url}/address/${id}`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
  deleteAddress(token, id) {
    return api.delete(`${url}/address/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  getProfile(token) {
    return api.get(`${url}/profile`, {
      headers: {
        Authorization: token,
      },
    });
  },
  updateProfile(token, data) {
    return api.put(`${url}`, data, {
      headers: {
        Authorization: token,
      },
    });
  },
};
