import api from './index';

const url = 'product';

export default {
  async getProduct(productId, params) {
    return api.get(`${url}/${productId}`, {
      params,
    });
  },
  async getProducts(params) {
    return api.get(`${url}s`, {
      params,
    });
  },
  async getProductCategories(params) {
    return api.get(`${url}/categories`, {
      params,
    });
  },
  async getProductSizes(params) {
    return api.get(`${url}/sizes`, {
      params,
    });
  },
  async getProductAddonCategories(params) {
    return api.get(`${url}/addon-categories`, {
      params,
    });
  },
  async getProductAddonItems(params) {
    return api.get(`${url}/addon-items`, {
      params,
    });
  },
};
