import api from './index';

const url = 'media';

export default {
  async findAllBanners(query) {
    return api.get(`${url}/banner`, {
      params: query,
    });
  },
};
