import notificationsApi from '../../api/notifications';
import { initializeApp, getApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const state = {
  firebaseToken: localStorage.firebaseToken,
	subscription: localStorage.subscription ? JSON.parse(localStorage.subscription) : null,
};

const mutations = {
  SET_FIREBASE_TOKEN(state, token) {
    if (token) {
      localStorage.firebaseToken = token;
      state.firebaseToken = token;
    } else {
      localStorage.removeItem('firebaseToken');
      state.firebaseToken = null;
    }
  },
	SET_SUBSCRIPTION(state, subscription) {
    if (subscription) {
      localStorage.subscription = JSON.stringify(subscription);
      state.subscription = subscription;
    } else {
      localStorage.removeItem('subscription');
      state.subscription = null;
    }
	},
};

const actions = {
  async subscribe(context, data) {
    const res = await notificationsApi.createSubscription(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }

		context.commit('SET_SUBSCRIPTION', data.meta);
    return res.data;
  },
  async unsubscribe(context, data) {
    const res = await notificationsApi.deleteSubscription(context.rootState.auth.token, context.rootState.merchant.profile.hq_merchant_id, context.rootState.merchant.profile.merchant_id, data);
    if (res?.status !== 200) {
      throw Error(res.data);
    }

		context.commit('SET_SUBSCRIPTION', null);
    return res.data;
  },
	initializeFirebase() {
		initializeApp({
			apiKey: "AIzaSyCKUx1Rn-p2JAUej4mVVw4A2EpKW_BLQ8c",
			authDomain: "eatmol-pwa-ordering.firebaseapp.com",
			projectId: "eatmol-pwa-ordering",
			storageBucket: "eatmol-pwa-ordering.appspot.com",
			messagingSenderId: "61291597379",
			appId: "1:61291597379:web:add576463c204c97a50ef6",
			measurementId: "G-T6K7Q9B3KC"
		});
	},
	async requestFirebasePermission(context) {
		try {
			const messaging = getMessaging(getApp());
			const token = await getToken(messaging, {vapidKey: 'BH2pzhcMgkAIVPjfB30svY-7iVxPOlLTKEbFhCFL8veCPYT9i2ZfDJTSb50yIhldc5tuuZXmu7m1FCpsprWf8ds'});
			context.commit('SET_FIREBASE_TOKEN', token);

			return token;
		} catch (e) {
			console.log(e);
		}
	},
};

const getters = {
  // ...
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
