import api from './index';

export default {
  getDistanceMatrix(params) {
    if (params.provider === 'distancematrixai') {
      return api.get(
        `https://api-prod.distancematrix.ai/maps/api/distancematrix/json`,
        { params }
      );
    } else {
      return api.get(
        `place/distance-matrix`,
        { params }
      );
    }
  },
  getDeliveryFee(params) {
    return api.get(`place/delivery-fee`, { params });
  },
  getDeliveryDiscount(params) {
    return api.get(`place/delivery-discount`, { params });
  },
};
