import configsApi from '../../api/configs';
import camelCase from 'lodash/camelCase';

const state = {};

const getters = {
  getGoogleParams(state) {
    return {
      client_id: state.google,
    };
  },
};

const mutations = {
  SET_CONFIG(state, { key, value }) {
    state[key] = value;
  },
};
const actions = {
  async getAdminOption(context, { key, params }) {
    const config = (await configsApi.getAdminOption(key, params)).data;
    context.commit('SET_CONFIG', {
      key: camelCase(key),
      value: config.value,
    });

    return config.value;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
