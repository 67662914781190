import productsApi from '../../api/products';

const state = {
  products: null,
  categories: null,
  sizes: null,
  addonCategories: null,
  addonItems: null,
};

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_PRODUCT_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_PRODUCT_SIZES(state, sizes) {
    state.sizes = sizes;
  },
  SET_PRODUCT_ADDON_CATEGORIES(state, addonCategories) {
    state.addonCategories = addonCategories;
  },
  SET_PRODUCT_ADDON_ITEMS(state, addonItems) {
    state.addonItems = addonItems;
  },
};

const actions = {
  async getProducts(context, params) {
    const products = (await productsApi.getProducts(params)).data;
    context.commit('SET_PRODUCTS', products);

    return products;
  },
  async getProductCategories(context, params) {
    const categories = (await productsApi.getProductCategories(params)).data;
    context.commit('SET_PRODUCT_CATEGORIES', categories);

    return categories;
  },
  async getProductSizes(context, params) {
    const sizes = (await productsApi.getProductSizes(params)).data;
    context.commit('SET_PRODUCT_SIZES', sizes);

    return sizes;
  },
  async getProductAddonCategories(context, params) {
    const addonCategories = (
      await productsApi.getProductAddonCategories(params)
    ).data;
    context.commit('SET_PRODUCT_ADDON_CATEGORIES', addonCategories);

    return addonCategories;
  },
  async getProductAddonItems(context, params) {
    const addonItems = (await productsApi.getProductAddonItems(params)).data;
    context.commit('SET_PRODUCT_ADDON_ITEMS', addonItems);

    return addonItems;
  },
};

const getters = {
  // ...
  getProducts(state) {
    return state.products?.reduce((r, e) => {
      r[e.item_id] = e;
      return r;
    }, {});
  },
  getProductCategories(state) {
    return state.categories?.reduce((r, e) => {
      r[e.id] = e;
      return r;
    }, {});
  },
  getProductSizes(state) {
    return state.sizes?.reduce((r, e) => {
      r[e.size_id] = e;
      return r;
    }, {});
  },
  getProductAddonCategories(state) {
    return state.addonCategories?.reduce((r, e) => {
      r[e.subcat_id] = e;
      return r;
    }, {});
  },
  getProductAddonItems(state) {
    return state.addonItems?.reduce((r, e) => {
      r[e.sub_item_id] = e;
      return r;
    }, {});
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
