import api from './index';

const url = 'notification';

export default {
  async createSubscription(token, hqMerchantId, merchantId, data) {
		return api.post(`${url}/subscribe`, data, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
    });
	},
  async deleteSubscription(token, hqMerchantId, merchantId, data) {
    return api.post(`${url}/unsubscribe`, data, {
      headers: {
        Authorization: token,
        hqmid: hqMerchantId,
        mid: merchantId,
      },
    });
  },
};
