import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF6347',
        secondary: '#B0BEC5',
        anchor: '#000',
        disabled: '#BDBDBD',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#A5D6A7',
        warning: '#FFC107',
        pending: '#B0BEC5',
        accepted: '#29B6F6',
        assigning: '#FDD835',
        'reassign-rider': '#FF4081',
        'rider-accepted': '#FF9800',
        'change-car': '#00E5FF',
        'rider-pick-up': '#D4E157',
        successful: '#66BB6A',
        declined: '#E0E0E0',
        cancelled: '#E0E0E0',
        "icon": "#FF6347",
        "active": "#FF6347",
        "border": "#FF6347",
        "button": "#000000",
        "toolbar": "#FF6347",
        "tertiary": "#E9851D",
        "background": "#FFFFFF",
        "buttonText": "#FFFFFF",
        "toolbarText": "#FFFFFF",
        "iconBackground": "#FF6347",
        "listBackground": "#F3F3F3",
        "buttonBackground": "#FF6347",    
      },
    },
  },
});
