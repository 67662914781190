<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    home() {
      return () => import(`@/components/Themes/${this.profile?.theme?.name ?? 'default'}/Home`);
    }
  },
  methods: {},
};
</script>
<template>
  <component
    :is="home"
    v-on:open-drawer="$emit('open-drawer')"
    v-if="profile && home"
  ></component>
</template>