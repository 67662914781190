import dataApi from '../../api/data';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
	async createDataAnalyticEvent(context, { event, data }) {
		dataApi.createAnalyticEvent(event, data);
	},
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
