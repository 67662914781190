import api from './index';

const url = 'order';

export default {
  async create(token, data) {
    if (data.isGuestCheckout) {
      return api.post(`${url}/guest`, data);
    } else {
      return api.post(`${url}`, data, {
        headers: {
          Authorization: token,
        },
      });
    }
  },
  async findAll(token, query) {
    return api.get(`${url}`, {
      params: query,
      headers: {
        Authorization: token,
      },
    });
  },
  async find(token, id, query) {
    return api.get(`${url}/${id}`, {
      params: query,
      headers: {
        Authorization: token,
      },
    });
  },
  async findReceipt(token, query) {
    return api.get(`${url}/receipt/${token}`, {
      params: query,
    });
  },
};
