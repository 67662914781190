import api from './index';

const url = 'configs';

export default {
  async getAdminOption(key, params) {
    return api.get(`${url}/${key}`, {
      params,
    });
  },
};
