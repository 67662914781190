import api from './index';

export default {
  getCredentials(token, type, params) {
    return api.get(`/payment/${type}/credentials`, {
      params,
      headers: {
        'Authorization': token
      }
    });
  },
};
