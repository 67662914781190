import placeApi from '../../api/places';

const state = {
  distance: null,
  duration: null,
};

const actions = {
  async getDistanceMatrix(context, params) {
    return (
      await placeApi.getDistanceMatrix({
        origins: params.pickupLatLng,
        destinations: params.deliveryLatLng,
        key: params.key,
        provider: params.provider,
      })
    ).data;
  },
  async getDeliveryFee(context, { time, distance, subTotal, stateId, merchantId }) {
    const deliveryFee = (
      await placeApi.getDeliveryFee({
        time, 
        distance,
        subTotal,
        stateId,
        merchantId,
      })
    ).data;
    context.commit(
      'cart/SET_DELIVERY_FEE', deliveryFee,
      { root: true }
    );
  },
  async getDeliveryDiscount(context, { distance, subTotal, merchantId }) {
    const deliveryDiscount = (
      await placeApi.getDeliveryDiscount({
        distance,
        subTotal,
        merchantId,
      })
    ).data;
    context.commit(
      'cart/SET_DELIVERY_DISCOUNT', deliveryDiscount,
      { root: true }
    );
  },
};

const getters = {};
const mutations = {
  SET_DISTANCE(state, distance) {
    state.distance = distance;
  },
  SET_DURATION(state, duration) {
    state.duration = duration;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
