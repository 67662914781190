import customersApi from '../../api/customers';

const state = {
  customer: localStorage.customer ? JSON.parse(localStorage.customer) : null,
  addresses: [],
  vouchers: [],
};

const mutations = {
  SET_CUSTOMER(state, customer) {
    if (customer) {
      localStorage.customer = JSON.stringify(customer);
      state.customer = customer;

      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'update-customer',
        value: customer,
      }, '*');
    } else {
      localStorage.removeItem('customer');
      state.customer = null;
    }
  },
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses ?? [];
  },
  SET_VOUCHERS(state, vouchers) {
    state.vouchers = vouchers ?? [];
  },
};

const actions = {
  async create(context, data) {
    const res = await customersApi.create(data);
    if (res?.status !== 200) {
      throw Error(res.data.message);
    }
    return res.data;
  },
  async getAddresses(context) {
    const result = await customersApi.findAllAddresses(context.rootState.auth?.token);
    context.commit('SET_ADDRESSES', result.data);
  },
  async getVoucher(context, id) {
    if (context.rootState.auth?.token) {
      const res = (await customersApi.findVoucher(context.rootState.auth.token, id));
      if (res?.status === 200) {
        return res?.data;
      } else {
        return null;
      }
    } else {
      return JSON.parse(localStorage.vouchers || '[]')?.find(v => v.id == id);
    }
  },
  async getVouchers(context, params) {
    let vouchers = [];
    if (context.rootState.auth?.token) {
      vouchers = (await customersApi.findAllVouchers(context.rootState.auth.token, params)).data;
    } else {
      vouchers = JSON.parse(localStorage.vouchers || '[]');
    }
    context.commit('SET_VOUCHERS', vouchers);
    return vouchers;
  },
  async claimVoucher(context, data) {
    if (context.rootState.auth?.token) {
      const res = await customersApi.claimVoucher(context.rootState.auth.token, data);
      if (res?.status !== 200) {
        throw Error(res.data.message);
      }

      return res.data;
    } else {
      let vouchers = JSON.parse(localStorage.vouchers || '[]');
      if (!vouchers.find(v => v.id === data.id)) {
        vouchers.push(data);
        localStorage.vouchers = JSON.stringify(vouchers);
      }

      return data;
    }
  },
  getAddress(context, id) {
    var token = context.rootState.auth.token;

    return customersApi.getAddress(token, id);
  },
  createAddress(context, data) {
    var token = context.rootState.auth.token;

    return new Promise((resolve, reject) => {
      customersApi
        .createAddress(token, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  updateAddress(context, { id, data }) {
    var token = context.rootState.auth.token;

    return new Promise((resolve, reject) => {
      customersApi
        .updateAddress(token, id, data)
        .then(res => {
          resolve(res);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  deleteAddress(context, { id }) {
    var token = context.rootState.auth.token;

    return new Promise((resolve, reject) => {
      customersApi
        .deleteAddress(token, id)
        .then(res => {
          resolve(res);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  getReservation(context) {
    var user = context.rootState.auth.user;

    return new Promise((resolve, reject) => {
      customersApi
        .getReservation(user.id)
        .then(res => {
          resolve(res.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  getProfile(context) {
    var token = context.rootState.auth.token;
    return new Promise((resolve, reject) => {
      customersApi
        .getProfile(token)
        .then(res => {
          resolve(res.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  updateProfile(context, data) {
    var token = context.rootState.auth.token;
    return new Promise((resolve, reject) => {
      customersApi
        .updateProfile(token, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
};

const getters = {
  getName(state) {
    return (state.customer ? ((state.customer?.lastName || '') + ' ' + state.customer?.firstName) : '')?.trim();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
