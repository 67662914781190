import logsApi from '../../api/logs';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
	async createLog(context, { type, data }) {
		logsApi.create(type, data);
	},
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
