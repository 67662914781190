import ordersApi from '../../api/orders';
const state = {
  order: null,
  list: [],
};

const mutations = {
  SET_ORDER(state, order) {
    state.order = order;
  },
  SET_ORDERS(state, orders) {
    state.list = orders;
  },
  SAVE_ORDER(state, order) {
    let orders = JSON.parse(localStorage.orders || '[]');
    if (!orders.find(o => o.orderId === order.order_id)) {
      orders.push({
        title: order.restaurant_name,
        orderId: order.order_id,
        orderIdToken: order.order_id_token,
        hostname: order.restaurant_hostname,
        service: order.trans_type,
        date: order.delivery_date,
        time: order.delivery_time,
        total: order.total_w_tax,
        status: order.status,
      });
      localStorage.orders = JSON.stringify(orders);
    }
  },
};

const actions = {
  async createOrder(context, data) {
    const res = await ordersApi.create(context.rootState.auth.token, data);
    if (res?.status !== 200) {
      throw Error(res.data.message);
    }

    return res.data;
  },
  async getOrder(context, { id, query }) {
    const res = await ordersApi.find(context.rootState.auth.token, id, query);
    if (res?.status != 200) {
      throw Error(res.data.message);
    }
    context.commit('SET_ORDER', res?.data);
    return res?.data;
  },
  async getOrders(context, query) {
    let orders = [];
    if (context.rootState.auth.token) {
      orders = (await ordersApi.findAll(context.rootState.auth.token, query))?.data;
      orders = orders.map(o => {
        return {
          title: o.restaurant_name,
          orderId: o.order_id,
          orderIdToken: o.order_id_token,
          hostname: o.restaurant_hostname,
          service: o.trans_type,
          date: o.delivery_date,
          time: o.delivery_time,
          total: o.total_w_tax,
          status: o.status,
        }
      });
    } else {
      orders = JSON.parse(localStorage.orders || '[]');
    }
    context.commit('SET_ORDERS', orders);
    return orders;
  },
  async getReceipt(context, { token, query }) {
    const res = await ordersApi.findReceipt(token, query);
    if (res?.status != 200) {
      throw Error(res.data.message);
    }
    context.commit('SET_ORDER', res?.data);

    // Save to Local Storage
    context.commit('SAVE_ORDER', res?.data);

    return res?.data;
  },
};

const getters = {
  // ...
  isDelivery(state) {
    return state.order?.trans_type === 'delivery';
  },
  isPickup(state) {
    return state.order?.trans_type === 'pickup';
  },
  isDinein(state) {
    return state.order?.trans_type === 'dinein';
  },
  isPaid(state) {
    return state.order?.status !== 'initial_order';
  },
  isCOD(state) {
    return state.order?.payment_type === 'cod';
  },
  isPayAtCounter(state) {
    return state.order?.payment_type === 'pyc';
  },
  isCashless(state) {
    return !['cod', 'pyc'].includes(state.order?.payment_type);
  },
  getVoucherAmount(state) {
    return state.order?.voucher_type === 'percentage' ? 
      (state.order?.voucher_amount * state.order?.sub_total / 100) : 
      state.order?.voucher_amount;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
